<template>
  <div>
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">Standort hinzufügen</h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <!-- BODY -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="locationForm"
        class="p-2"
        type="button"
        @submit.prevent="submitLocation"
      >
        <!-- Name -->
        <b-form-group label="Name" label-for="Name">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.name"
              :state="errors.length > 0 ? false : null"
              placeholder="Münster-Ost"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Name -->
        <b-form-group label="Kurzname" label-for="Kurzname">
          <validation-provider
            #default="{ errors }"
            name="Kurzname"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.shortName"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.shortName"
              :state="errors.length > 0 ? false : null"
              placeholder="MS-O"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Stadt -->
        <b-form-group label="Stadt" label-for="Stadt">
          <validation-provider
            #default="{ errors }"
            name="Stadt"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.city"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.city"
              :state="errors.length > 0 ? false : null"
              placeholder="Münster"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Straße -->
        <b-form-group label="Straße" label-for="Straße">
          <validation-provider
            #default="{ errors }"
            name="Straße"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.street"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.street"
              :state="errors.length > 0 ? false : null"
              placeholder="Rudolf-Diesel Straße"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Straße Nr-->
        <b-form-group label="Nummer" label-for="Nummer">
          <validation-provider
            #default="{ errors }"
            name="Nummer"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.streetNumber"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.streetNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="29-31"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- PLZ-->
        <b-form-group label="PLZ" label-for="PLZ">
          <validation-provider
            #default="{ errors }"
            rules="required|digits:5"
            name="PLZ"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editLocationEntry.zipCode"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="location.zipCode"
              :state="errors.length > 0 ? false : null"
              placeholder="48157"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Land -->
        <b-form-group label="Land" label-for="">
          <validation-provider
            #default="{ errors }"
            name="Land"
            rules="required"
          >
            <v-select
              v-if="isEditMode"
              id="v-country"
              v-model="editLocationEntry.country"
              :clearable="false"
              label="title"
              :options="countryOptions"
              :state="errors.length > 0 ? false : null"
            />
            <v-select
              v-else
              id="v-country"
              v-model="location.country"
              :clearable="false"
              label="title"
              :options="countryOptions"
              :state="errors.length > 0 ? false : null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Beschreibung-->
        <b-form-group label="Beschreibung" label-for="Beschreibung">
          <b-form-textarea
            v-if="isEditMode"
            id="description"
            v-model="editLocationEntry.description"
            rows="3"
            autofocus
            trim
          />
          <b-form-textarea
            v-else
            id="description"
            v-model="location.description"
            rows="3"
            autofocus
            trim
            placeholder="Beschreibung einfügen..."
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="button"
            @click="validationForm"
          >
            <div v-if="isEditMode">Aktualisieren</div>
            <div v-else>Hinzufügen</div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div></b-form
      >
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import LocationList from '@/views/pages/locations/list/LocationsList.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    ToastificationContent,
    vSelect,
  },
  props: ['id', 'isEditMode', 'editLocationEntry'],
  data() {
    return {
      location: {
        name: '',
        city: '',
        street: '',
        streetNumber: '',
        zipCode: '',
        country: '',
        description: '',
        shortName: '',
      },
      countryOptions: ['Deutschland', 'Schweiz', 'Österreich'],
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitLocation()
        }
      })
    },
    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },
    submitLocation() {
      // EditMode
      if (this.isEditMode) {
        this.$store
          .dispatch('updateLocation', this.editLocationEntry)
          .then(() => {
            console.log('updateLocation success')
            this.$store.dispatch('getLocationsEntries')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
            this.toastNotification(
              'Standort aktualisiert',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Standort konnte nicht aktualisiert werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      } else {
        // AddMode
        this.$store
          .dispatch('addLocation', this.location)
          .then(() => {
            console.log('addLocation success')
            this.$store.dispatch('getLocationsEntries')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
            this.toastNotification(
              'Standort hinzugefügt',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Standort konnte nicht hinzugefügt werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      }
    },
    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang=""></style>
